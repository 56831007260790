@supports (-webkit-touch-callout: none) {

    html,
    body {
        overscroll-behavior: none;
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
        overflow-x: hidden;
    }
}

/* navbar */
.navbar {
    color: #a9a7a9;
    background-color: #fdfbfd;
    width: 100%;
}

.nav-btn {
    color: #a9a7a9;
    border: none;
    background-color: #fdfbfd;
    border-bottom: 2px solid #dbdbdb;
}

.ok-btn {
    color: #71777c;
    border: none;
    background-color: #fdfbfd;
}

.fill {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.fill img {
    flex-shrink: 0;
    min-width: 100%;
}

/* content */
.content {
    background-color: #ececec;
    padding: 24px;
}

.button {
    background-color: #ee7324;
    color: white;
    font-weight: 600;
    width: 150px;
    padding: 8px;
    border: none;
    border-radius: 2px;
}

.button:disabled {
    background-color: #ee71248a;
}

.add-btn {
    background-color: #ececec;
    color: #ee7324;
    font-weight: 600;
    padding: 8px 12px;
    border: none;
    border-radius: 2px;
}

.add-lead {
    border-radius: 4px;
    border: none;
    background-color: #ee7324;
    color: white;
    width: 100%;
    padding: 8px 0px 8px 0px;
    font-weight: 600;
}

.lead-button {
    background-color: #008f00;
    color: white;
    font-weight: 600;
    padding: 8px;
    border: none;
    border-radius: 2px;
}

.lead-button:disabled {
    background-color: #008f007a;
}

.success-button {
    background-color: #ee7324;
    color: white;
    font-weight: 600;
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 2px;
}

.success-img {
    width: 150px;
    height: 150px;
}

.success-contact {
    display: flex;
    justify-content: center;
    min-height: 80px;
    padding: 10px;
}

.success-contact img {
    width: 100%;
    height: 80px;
}

.datepicker-input {
    padding: 15px;
}

/* login page */
.login {
    max-width: 540px;
}

a {
    color: #717092;
    text-decoration: none;
}

.form-check-input {
    width: 24px;
    height: 24px;
}

.form-check-input:checked {
    background-color: #ee7324;
    border: none;
}

.scan-link {
    color: #81709d;
}

.continue-btn {
    background-color: #ee7324;
    color: white !important;
    border: none;
    font-weight: 600;
    border-radius: 0px;
}

.continue-input {
    border-radius: 0px;
}

@media all and (max-width: 800px) {
    .banner-container {
        padding: 0px !important;
    }
}

.page-container {
    max-width: 730px;
}

.banner-container {
    max-width: 700px;
}

.ticketino-logo {
    width: 300px !important;
    padding: 20px 20px 0px 20px;
}